import config from "../constants/config";

const brand = config.APP_NAME;

const routePaths = Object.freeze({
  UHF: {
    authenticatedDefault: "/dashboard",
    default: "/",
    login: "/login",
    dashboard: "/dashboard",
    myNotifications: "/Notification",
    documents: "/DocumentsScreen",
    contact: "/ContactScreen",
    announcements: "/AnnouncementNotification",
    curamLife:
      "https://www.carynhealth.com/curamlife/",
    telemed: "https://members.myflexcare.com/login/member",
    pharma: "https://fliptrx.com/",
    programInformation: "/ProgramInformation",
    myNeeds: "/MyNeeds",
    myTransaction: "/Transaction",
    findAProvider:
      "https://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=netwell.network",
    healthQuestionary: "/Medical",
    faq: "https://www.universalhealthfellowship.org/FAQs/",
    spanishFaq:"",
    hippa: "",
    hipaaAuth: "",
    uhfCuram: "/MobileCuramLifeCardNotification",
    ChangePaymentScreen: "/ChangePaymentScreen",
    MembershipCard: "/MembershipCard",
    MyNeeds: "MyNeeds",
    HealthToolCard: "/HealthToolCard",
    aiChat: "/AIchat",
    flexCare: "",
    kellyChoice: "",
    curamLifeCareApp: "",
    alykaHealth: "",
  },
  NETWELL: {
    authenticatedDefault: "/dashboard",
    default: "/",
    login: "/login",
    dashboard: "/dashboard",
    myNotifications: "/Notification",
    documents: "/DocumentsScreen",
    contact: "/ContactScreen",
    announcements: "/AnnouncementNotification",
    curamLife: "",
    pharma: "",
    telemed: "",
    programInformation: "/ProgramInformation",
    myNeeds: "/MyNeeds",
    myTransaction: "/Transaction",
    findAProvider:
      "http://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=universalhealthshare",
    healthQuestionary: "/Medical",
    faq: "",
    spanishFaq:"",
    hippa: "/Hipaa",
    hipaaAuth: "/HipaaAuthorization",
    uhfCuram: "",
    ChangePaymentScreen: "/ChangePaymentScreen",
    MembershipCard: "/MembershipCard",
    MyNeeds: "MyNeeds",
    HealthToolCard: "/MembershipCard",
    aiChat: "/AIchat",
    flexCare: "",
    kellyChoice: "",
    curamLifeCareApp: "",
    alykaHealth: "",
  },
  CURAMLIFE: {
    authenticatedDefault: "/dashboard",
    default: "/",
    login: "/login",
    dashboard: "/dashboard",
    myNotifications: "/Notification",
    documents: "/DocumentsScreen",
    contact: "/ContactScreen",
    announcements: "/AnnouncementNotification",
    curamLife: "",
    pharma: "",
    telemed: "",
    programInformation: "/ProgramInformation",
    myNeeds: "/MyNeeds",
    myTransaction: "/Transaction",
    findAProvider:
      "http://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=universalhealthshare",
    healthQuestionary: "/Medical",
    faq: "https://curamlife-prod.s3.us-east-2.amazonaws.com/CuramLife_Employee_FAQs.pdf",
    spanishFaq:'https://curamlife-prod.s3.us-east-2.amazonaws.com/CuramLife_Employee_FAQs_(Spanish).pdf',
    hippa: "/Hipaa",
    hipaaAuth: "/HipaaAuthorization",
    uhfCuram: "",
    ChangePaymentScreen: "/ChangePaymentScreen",
    MembershipCard: "/MembershipCard",
    MyNeeds: "MyNeeds",
    HealthToolCard: "/MembershipCard",
    aiChat: "/AIchat",
    flexCare: "https://members.myflexcare.com/login/member",
    kellyChoice: "https://secure.gethealthie.com/users/sign_in?target_url=/",
    curamLifeCareApp: "https://www.carynhealth.com/curamlife/",
    alykaHealth: "https://app.alykahealth.com/b2b_onboard?siteId=CURAM_LIFE",
  },
});

export default routePaths;

export const getRoutePaths = routePaths[brand as keyof typeof routePaths];
