import React, {
  ElementType,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Header as HeaderLibrary } from "carynhealth-component-library";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthenticateContext";
import config from "../../config/constants/config";
import routePaths from "../../config/constants/routePaths";
import data from "./data";
import StyleWrapper from "./style";
import { useLocation } from "react-router-dom";
import {
  Link,
  useMediaQuery,
  useTheme,
  IconButton,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import titles from "../../config/constants/titles";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getMenuData } from "./Sidebar/handler";
import AskCarynLogo from "../../assests/images/uhf/ask-caryn-beta-responsive.png";
import CloseIcon from "@mui/icons-material/Close";
import { isTablet, isMobile } from "react-device-detect";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import { getOs } from "../RightTopMemberTabs/utility";

const headerData = { ...data[config.APP_NAME as keyof typeof data] };
const routes = routePaths[config.APP_NAME as keyof typeof routePaths];
const isUHF = config.APP_NAME === config.BRAND_UHF;
const isCuramlife = config.APP_NAME === config.BRAND_CURAMLIFE;
const isNetwell = config.APP_NAME === config.BRAND_NETWELL;

const names = [
  { name: "English", value: "en" },
  { name: "Spanish", value: "es" },
];

const LoginHeaderStyle = styled("div")(({ theme }) => {
  return {
    alignContent: "center",
    textAlign: "center",
    boxShadow:
      "0 2px 4px 0 rgba(0,0,0,.2), 0 1px 10px 0 rgba(0,0,0,.12), 0 4px 5px 0 rgba(0,0,0,.14)",
    height: 56,
    zIndex: 999,
    backgroundColor: isUHF ? "#543379" : "#ffff",
    color: isUHF ? "#ffff" : "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: '"Roboto",sans-serif',
    fontSize: 20,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: 0.15,
    marginBottom: 26,
    position: "fixed",
    width: "100%",
  };
});
const Header: React.FC = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(600));
  let [pageName, setPagename] = useState<string>("Employee Portal");
  const planID: any = getLocalStorage("planId");
  const clientId: any = getLocalStorage("CLIENT_ID");
  const [SidebarIndex, setSidebarIndex] = useState(-1);
  const [sideBarObj, setSideBarObj] = React.useState<any>(null);
  const [currentLanguage, setCurrentLanguage] = useState<"en" | "es">("en");
  let [link, setLink] = React.useState(
    "https://www.carynhealth.com/curamlife/curamlife-care-coordination-app/"
  );
  let [fliptLink, setFliptLink] = React.useState("");

  useEffect(() => {
    getPageName();
  }, [location.pathname, SidebarIndex]);

  useEffect(() => {
    getSimpleShareData();
    auth.authenticatedInfo.isAuthenticated && displayMenuListData();
  }, [auth.authenticatedInfo.isAuthenticated, planID]);

  const displayMenuListData = async () => {
    let lang: any = getLocalStorage("Language");
    setCurrentLanguage(lang);
    let isCuramCare = false;
    let isAlykaApp = false;

    const dataJSON = getLocalStorage("memebr-details") || "";
    if (dataJSON) {
      const Array = JSON.parse(dataJSON);
      let value = Array.curamLifeAddons.some(
        (id: { addonName: any }) =>
          id.addonName === "CuramLife Care Coordination App"
      );
      isCuramCare = value;
      let isAlyka = Array.curamLifeAddons.some(
        (id: { addonName: any }) =>
          id.addonName === "ALYKA Health App"
      );
      isAlykaApp = isAlyka;
    }

    const updatedSideBar = headerData.sideBarData.listOfSideBar.filter((item: any) => {
      if (item.navigate.path === routes.curamLifeCareApp) {
        return isCuramCare;
      }
      if (item.navigate.path === routes.alykaHealth) {
        return isAlykaApp;
      }
      return true;
    });

    let sortedArray = await getMenuData({
      planId: planID,
      networkName: "uhf",
      clientId: clientId,
      DataArray: updatedSideBar,
    });
    headerData.sideBarData.listOfSideBar = sortedArray;
  };

  const getPageName = async () => {
    if (location.pathname !== routes.dashboard && showText) {
      let routekey = await getKeyByValue(routes, location.pathname, "byValue");
      let titleKey = await getKeyByValue(titles, routekey, "byKey");
      let name = "";
      if (titleKey === "myNeeds" && config.APP_NAME == config.BRAND_NETWELL) {
        name = titles.shareRequests;
      } else {
        name = titles[titleKey as keyof typeof titles].toLocaleLowerCase();
      }
      setPagename(name);
    }
    let drawerIndex = headerData?.sideBarData?.listOfSideBar?.find(
      (x: any) => x.navigate.path == location.pathname
    );
    if (
      drawerIndex &&
      drawerIndex.component == null &&
      headerData.sideBarData.selectedItem.selectedIndex != drawerIndex?.index
    ) {
      headerData.sideBarData.selectedItem.selectedIndex = drawerIndex?.index;
    }
  };

  function getKeyByValue(object: any, value: any, type: string) {
    if (type === "byValue") {
      return Object.keys(object).find(
        (key: string | number) => object[key] === value
      );
    }
    return Object.keys(object).find(
      (key: string) => key.toLocaleLowerCase() === value.toLocaleLowerCase()
    );
  }

  const logout = useCallback(() => {
    auth.resetAuthenticateInfo();
    window.location.href = "/";
  }, []);

  const rightIconsCallback = useCallback(
    (data: any) => {
      if (data?.icon?.alt === "Logout") return logout();
    },
    [headerData]
  );

  const sidebarCallback = useCallback(
    (data: any) => {
      const object: any = headerData.sideBarData.listOfSideBar[data.index];
      const lang = getLocalStorage("Language");
      setSidebarIndex(-1);
      if (object?.label === "Sign Out") {
        setSidebarIndex(data.index);
        setSideBarObj({});
        return logout();
      } else if (object?.label === "FlexCare") {
        openCuramLifeCare();
      } else if (object?.label === "Kelly's Choice") {
        openFlipt();
      } else if (object?.label === "CuramLife Care Coordination App") {
        opentelemed();
      } else if (object?.label === "ALYKA Health") {
        openAlyka();
      } else if (object?.navigate?.newTab) {
        setSidebarIndex(data.index);
        setSideBarObj({});
        if(lang === "es"){
          return window.open(routes.spanishFaq)
        }
        return window.open(object?.navigate?.path);
      } else if (object?.component) {
        if (object?.navigate?.path && showText) {
          setSidebarIndex(data.index);
          setSideBarObj({});
          navigate(object?.navigate?.path);
        } else {
          let popupObj = headerData?.sideBarData?.listOfSideBar[data.index];
          setSideBarObj({ ...popupObj });
          setSidebarIndex(data.index);
          console.log(object?.component);
        }
      } else {
        setSidebarIndex(data.index);
        setSideBarObj({});
        navigate(object?.navigate?.path);
      }
    },
    [props]
  );

  function closePopup() {
    setSideBarObj(null);
  }

  const getSimpleShareData = () => {
    var os = getOs();
    console.log("==========RIGHT DRAWER IS====", os);
    switch (os) {
      case "Mac OS":
        setLink("https://apps.apple.com/us/app/curamlife/id1609101662");
        setFliptLink("https://apps.apple.com/us/app/healthie/id1112029170");
        break;
      case "iOS":
        setLink("https://apps.apple.com/us/app/curamlife/id1609101662");
        setFliptLink("https://apps.apple.com/us/app/healthie/id1112029170");
        break;
      case "Windows":
        setLink(
          "https://www.carynhealth.com/curamlife/"
        );
        setFliptLink(
          "https://secure.gethealthie.com/users/sign_in?target_url=/"
        );
        break;
      case "Android":
        setLink("https://play.google.com/store/apps/details?id=com.curamlife");
        setFliptLink(
          "https://play.google.com/store/apps/details?id=com.healthie.app.healthie"
        );
        break;
      case "Linux":
        setLink(
          "https://www.carynhealth.com/curamlife/"
        );
        setFliptLink(
          "https://secure.gethealthie.com/users/sign_in?target_url=/"
        );
        break;
      default:
        setLink(
          "https://www.carynhealth.com/curamlife/"
        );
        setFliptLink(
          "https://secure.gethealthie.com/users/sign_in?target_url=/"
        );
    }
  };

  const opentelemed = () => {
    window.open(link);
  };
  const openFlipt = () => {
    window.open(fliptLink);
  };
  const openCuramLifeCare = () => {
    var os = getOs();
    if (os == "Mac OS" || os == "Windows" || os == "Linux")
      window.open("https://members.myflexcare.com/login/member");
    else if (os == "iOS")
      window.open(
        "https://apps.apple.com/us/app/flexcare-digital-health/id1538778813"
      );
    else if (os == "Android")
      window.open(
        "https://play.google.com/store/apps/details?id=com.FlexCare.app&pli=1"
      );
  };

  const openAlyka = () => {
    var os = getOs();
    window.open("https://app.alykahealth.com/b2b_onboard?siteId=CURAM_LIFE");
  };

  if (!auth.authenticatedInfo.isAuthenticated) {
    if (showText) {
      return (
        <LoginHeaderStyle>
          <div>{isCuramlife ? t('EmployeePortal') :t('MemberPortal')}</div>
        </LoginHeaderStyle>
      );
    }
    return null;
  }

  if (!headerData.user) {
    headerData.user = auth.authenticatedInfo.name;
    headerData.rightSectionIconList.rightCallBack = rightIconsCallback;
    headerData.sideBarData.sideBarCallBack = sidebarCallback;
  }

  if (location.pathname === routes.aiChat && showText)
    return (
      <StyleWrapper>
        <div className="header-containers  fixed-top">
          <img src={AskCarynLogo} className="logo" />
          <div className="caption">{titles.AiAssistantTitle}</div>
          <IconButton
            aria-label="close"
            onClick={() => navigate(routes.dashboard)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
      </StyleWrapper>
    );

  if (sideBarObj?.component) {
    const Comp = sideBarObj.component;
    sideBarObj.component = React.cloneElement(Comp, {
      onClick: () => closePopup(),
    });
  }

  const onOptionChangeHandler = (event: SelectChangeEvent) => {
    let lang: any = event.target.value as string;
    setLocalStorage("Language", lang);
    i18n.changeLanguage(lang);
    setCurrentLanguage(lang);
  };
  return (
    <StyleWrapper>
      <div className="languageSelect">
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">
            {t('SelectYourLanguage')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentLanguage}
            label={t('SelectYourLanguage')}
            onChange={onOptionChangeHandler}
          >
            {names.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <HeaderLibrary
        {...headerData}
        variant={
          location.pathname !== routes.dashboard && showText ? "mobile" : "web"
        }
        style={
          location.pathname === routes.myTransaction && isMobileScreen
            ? { position: "fixed" }
            : {}
        }
        MobileViewComponant={() => (
          <div className="mobile_headerNetWell">
            <Link
              style={{
                color: `${isNetwell || isCuramlife ? "#000" : "#fff"}`,
                width: "24px",
                height: "24px",
              }}
              href="/dashboard"
            >
              <ArrowBackIcon></ArrowBackIcon>
            </Link>
            <div className="mobile_header_titleNetWell">{pageName}</div>
          </div>
        )}
      />

      {sideBarObj?.component && sideBarObj.component}
    </StyleWrapper>
  );
};

export default Header;
