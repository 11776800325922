import { apiService } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import { documentsInfo } from "./data";
import UHFDocuments from "../../../assests/images/uhf/documents.svg";
import NetwellDocuments from "../../../assests/images/netwell/documents.svg";
import { DetailsType } from "./interface";
import titles from "../../../config/constants/titles";
import messages from "../../../config/constants/messages";
import { t } from "i18next";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

const brand = config.APP_NAME;

export const getWelcomeBookletLink = async (type: string) => {
  try {
    const mail = localStorage.getItem("userMail");
    const request = {
      URL: `${config.URLS.baseAPI}${config.URLS.dashboardPlanInfo}`,
      data: { email: mail, type },
    };
    const response = await apiService.default.post(request);
    const providerLink = response.data[0].fieldValue;
    return providerLink;
  } catch (error: any) {
    return error;
  }
};

export const getDocuments = (docRequest: DetailsType) => {
  const { clientId, ...rest } = docRequest;
  const details: any = documentsInfo[brand as keyof typeof documentsInfo];
  const packageName = getLocalStorage("packageName");
  const dataJSON = getLocalStorage("memebr-details") || "";
  if (brand === config.BRAND_CURAMLIFE) {
    details.documents.map((data: any) => {
      if ((data?.addon && dataJSON) || (data?.planlist && packageName)) {
        const Array = JSON.parse(dataJSON);
      
        const doesObjectExist = (id: { addonName: any; }) => {
          return data?.addon?.includes(id.addonName);
        };
      
        data.activeFlag = (data?.planlist?.includes(packageName) || 
                      !Array?.curamLifeAddons
                        .map((id: { addonName: any; }) => doesObjectExist(id))
                        .every((value: any) => !value));
      }
    });
    let array: any[] = [];
    details.documents.map((data: any, index: number) => {
      if (data.activeFlag) {
        array.push(data);
      }
    });

    return array.map((data: any, index: number) => {
      return data;
    });
  } else if (brand === config.BRAND_UHF) {
    return details.documents.map((data: any) => {
      if (data.show) {
        return data.show() && data;
      } else if (data.disable) {
        return { ...data, disable: rest[data.disable as keyof typeof rest] };
      }
      return data;
    });
  } else if (brand === config.BRAND_NETWELL) {
    const documentData = details.documents.find(({ id }: any) =>
      id.includes(clientId)
    );
    return documentData?.list?.length ? documentData.list : [];
  }
};

export const getDocumentTileDetails = ({
  clientId,
  disableUHFWelcome,
  disableUHFSharing,
}: DetailsType) => {
  const notifications =
    getDocuments({ clientId, disableUHFWelcome, disableUHFSharing }) || [];
  return {
    title: t("Documents"), //titles.Documents,
    image: brand === config.BRAND_NETWELL ? NetwellDocuments : UHFDocuments,
    isUHF: brand === config.BRAND_UHF,
    notifications,
    errorMessage: notifications.length
      ? ""
      : documentsInfo[brand as keyof typeof documentsInfo]["not-found"],
  };
};

export const getDocumentsDataByID = async (planId: any, memberSSN: any) => {
  const lang:any|'en' = getLocalStorage("Language");
  let value:'English'|any;
  switch (lang) {
    case "en":
      value = "English";
      break;
    case "es":
      value = "Spanish";
      break;
    default: value = ""
      break;
  }
  let URL: string =
    config.URLS.baseAPI + config.URLS.getDocumentByPlanId + planId;
  if (config.APP_NAME === config.BRAND_UHF)
    URL =
      config.URLS.baseAPI +
      config.URLS.getDocumentByPlanId +
      planId +
      "/" +
      memberSSN +
      "/" +
      value;
      if (config.APP_NAME === config.BRAND_CURAMLIFE)
        URL =
          config.URLS.baseAPI +
          config.URLS.getDocumentByPlanId +
          memberSSN +
          "/" +
          value;
  try {
    const request = {
      URL: URL,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    return {
      title: t("Documents"),
      image: brand === config.BRAND_NETWELL ? NetwellDocuments : UHFDocuments,
      isUHF: brand === config.BRAND_UHF,
      apiResponse,
      errorMessage: messages.NO_DOCUMENTS,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.NO_DOCUMENTS,
    });
  }
};
