import React, { useEffect } from "react";
import { getPlainIData, getRxSimpleShareData } from "./handler";
import { getOs } from "./utility";
import styled from "@mui/system/styled";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import titles from "../../config/constants/titles";
import messages from "../../config/constants/messages";
import DialogPopup from "../../UI/DialogPopup";
import data from "../Header/data";
import config from "../../config/constants/config";

const headerData = { ...data[config.APP_NAME as keyof typeof data] };
interface RightDrawerProps {
  pharmaShow: boolean;
  planID: string;
  headerTaps?: boolean;
}

const RightDrawerWapper = styled("div")(({ theme }) => {
  return {
    ".noticeIcon": {
      display: "flex",
      width: "77%",
      justifyContent: "end",
      img: {
        width: "30px",
        cursor: "pointer",
      },
    },
    ".rightdrawer": {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: "5vw",
      padding: "0px 0px 0px 35px",
      justifyContent: "start",
      gap: 30,
      width: "25vw"
    },
    ".rightdrawer_card": {
      width: "100px",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: 30,
      marginTop: 40,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      textAlign: "center !important",
    },
    ".rightdrawer_card_name": {
      fontSize: "16px",
      fontWeight: 500,
      marginTop: "1vw",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.13,
      letterSpacing: 0.5,
      color: "#333",
      width: 150
    },
    ".memberapps_row_container": {
      display: "ruby",
      justifyContent: "left",
      alignItems: "center",
    },
    ".memberapps_card_container": {
      margin: "4vw",
      width: "30vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    ".memberapps_apps_img": {
      width: "18vw",
      height: "18vw",
    },
    ".rightdrawer_card_img": {
      height: "84px",
      width: "84px",
    },
    ".memberapps_card_name": {
      fontSize: "3.4vw",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.13",
      letterSpacing: ".5px",
      color: "#333",
      marginTop: "2vw !important",
    },
  };
});

export const RightDrawer = (props: RightDrawerProps) => {
  let [link, setLink] = React.useState(
    "https://www.carynhealth.com/curamlife/curamlife-care-coordination-app/"
  );
  const [pharmaShow, setPharmaShow] = React.useState(props.pharmaShow);
  let [fliptLink, setFliptLink] = React.useState("");
  const { t } = useTranslation();
  let [addonsValue, setAddonsValue] = React.useState(false);
  let [isAlykaApp, setIsAlykaApp] = React.useState(false);
  let [popup, setPopup] = React.useState(false);
  const [sideBarObj, setSideBarObj] = React.useState<any>(null);

  useEffect(() => {
    getSimpleShareData();
    const dataJSON = getLocalStorage("memebr-details") || "";
    if (dataJSON) {
      const Array = JSON.parse(dataJSON);
      let value = Array.curamLifeAddons.some(
        (id: { addonName: any }) =>
          id.addonName === "CuramLife Care Coordination App"
      );
      console.log(value);
      setAddonsValue(value);
      let isAlyka = Array.curamLifeAddons.some(
        (id: { addonName: any }) =>
          id.addonName === "ALYKA Health App"
      );
      setIsAlykaApp(isAlyka);
    }
  }, []);

  const getSimpleShareData = () => {
    var os = getOs();
    console.log("==========RIGHT DRAWER IS====", os);
    switch (os) {
      case "Mac OS":
        setLink("https://apps.apple.com/us/app/curamlife/id1609101662");
        setFliptLink("https://apps.apple.com/us/app/healthie/id1112029170");
        break;
      case "iOS":
        setLink("https://apps.apple.com/us/app/curamlife/id1609101662");
        setFliptLink("https://apps.apple.com/us/app/healthie/id1112029170");
        break;
      case "Windows":
        setLink(
          "https://www.carynhealth.com/curamlife/"
        );
        setFliptLink(
          "https://secure.gethealthie.com/users/sign_in?target_url=/"
        );
        break;
      case "Android":
        setLink("https://play.google.com/store/apps/details?id=com.curamlife");
        setFliptLink(
          "https://play.google.com/store/apps/details?id=com.healthie.app.healthie"
        );
        break;
      case "Linux":
        setLink(
          "https://www.carynhealth.com/curamlife/"
        );
        setFliptLink(
          "https://secure.gethealthie.com/users/sign_in?target_url=/"
        );
        break;
      default:
        setLink(
          "https://www.carynhealth.com/curamlife/"
        );
        setFliptLink(
          "https://secure.gethealthie.com/users/sign_in?target_url=/"
        );
    }
  };

  const opentelemed = () => {
    window.open(link);
  };
  const openFlipt = () => {
    window.open(fliptLink);
  };
  const openCuramLifeCare = () => {
    var os = getOs();
    if (os == "Mac OS" || os == "Windows" || os == "Linux")
      window.open("https://members.myflexcare.com/login/member");
    else if (os == "iOS")
      window.open(
        "https://apps.apple.com/us/app/flexcare-digital-health/id1538778813"
      );
    else if (os == "Android")
      window.open(
        "https://play.google.com/store/apps/details?id=com.FlexCare.app&pli=1"
      );
  };

  const openAlyka = () => {
    var os = getOs();
    window.open("https://app.alykahealth.com/b2b_onboard?siteId=CURAM_LIFE");
  };

  const handleOk = () => {
    setPopup(false)
    let popupObj = headerData?.sideBarData?.listOfSideBar[3];
    setSideBarObj({ ...popupObj });
    
  };
  if(sideBarObj?.component){
    const Comp = sideBarObj.component
    sideBarObj.component = React.cloneElement(Comp,{onClick:()=>closePopup()})
  }
  function closePopup(){
    setSideBarObj(null)
   };
  return (
    <>
      <RightDrawerWapper>
        <div className="noticeIcon">
          <img
            onClick={() => {
              setPopup(true);
            }}
            src={require("../../assests/images/notification_notice_icon.png")}
          />
        </div>
        <div
          className={
            props.headerTaps ? "memberapps_row_container" : "rightdrawer "
          }
        >
          <div
            className={
              props.headerTaps
                ? "memberapps_card_container"
                : "rightdrawer_card text-center"
            }
            onClick={() => openCuramLifeCare()}
          >
            <div>
              <img
                src={require("../../assests/images/curamlife/flex.webp")}
                className={
                  props.headerTaps
                    ? "memberapps_apps_img"
                    : "rightdrawer_card_img"
                }
                style={{ width: "90px" }}
              />
            </div>
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_name"
                  : "rightdrawer_card_name"
              }
              style={{}}
            >
              {t("FlexCare")}
            </div>
          </div>

          <div
            className={
              props.headerTaps
                ? "memberapps_card_container"
                : "rightdrawer_card text-center"
            }
            onClick={() => openFlipt()}
          >
            <div>
              <img
                src={require("../../assests/images/curamlife/healthie.png")}
                className={
                  props.headerTaps
                    ? "memberapps_apps_img"
                    : "rightdrawer_card_img"
                }
              />
            </div>
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_name"
                  : "rightdrawer_card_name"
              }
              style={{ marginTop: "4px !important" }}
            >
              {t("KellyChoice")}
            </div>
          </div>

          {addonsValue && (
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_container"
                  : "rightdrawer_card text-center"
              }
              onClick={() => opentelemed()}
            >
              <div>
                <img
                  src={
                    require("../../assests/images/curamlife/curam-life.svg")
                      .default
                  }
                  className={
                    props.headerTaps
                      ? "memberapps_apps_img"
                      : "rightdrawer_card_img"
                  }
                />
              </div>
              <div
                className={
                  props.headerTaps
                    ? "memberapps_card_name"
                    : "rightdrawer_card_name"
                }
              >
                {t("CuramLifeCareApp")}
              </div>
            </div>
          )}
          {isAlykaApp && (
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_container"
                  : "rightdrawer_card text-center"
              }
              onClick={() => openAlyka()}
            >
              <div>
                <img
                  src={
                    require("../../assests/images/curamlife/alyka.png")
                  }
                  className={
                    props.headerTaps
                      ? "memberapps_apps_img"
                      : "rightdrawer_card_img"
                  }
                />
              </div>
              <div
                className={
                  props.headerTaps
                    ? "memberapps_card_name"
                    : "rightdrawer_card_name"
                }
              >
                {t("AlykaApp")}
              </div>
            </div>
          )}
        </div>
      </RightDrawerWapper>
      <DialogPopup
          isShow={popup}
          popupData={{
            "id": 9999999,
            'description':'getAnnouncemetDetails.title04',
            "title": "getAnnouncemetDetails.title04",
            "message": "getAnnouncemetDetails.message04",
            "type": "NOTICE",
            "createdDate": "2023-10-10T05:30:00.000+00:00",
            "updated_date": "2023-10-10T14:58:06.000+00:00"
        }}
          handleClose={()=>setPopup(false)}
          handleOk={handleOk}
        />
         {sideBarObj?.component && sideBarObj.component}
    </>
  );
};
